import React from "react";
import ico_social_1 from "../../assets/img/social_1.svg";
import ico_social_2 from "../../assets/img/social_2.svg";
import ico_social_3 from "../../assets/img/social_3.svg";
import ico_social_4 from "../../assets/img/social_4.svg";
import ico_social_5 from "../../assets/img/social_5.svg";
import ico_social_6 from "../../assets/img/social_6.svg";
import ico_flag_1 from "../../assets/img/flag_1.svg";
import ico_flag_2 from "../../assets/img/flag_2.svg";
import ico_flag_3 from "../../assets/img/flag_3.svg";
import SwitchLanguage from "../switchLanguage/switchLanguage";
import { useTranslation } from "react-i18next";

const styles = {
  headings:
    "footer-text-gray-primary footer-uppercase footer-font-semibold footer-text-xs footer-tracking-[0.5px]",
  options:
    "footer-text-white footer-text-sm footer-font-semibold footer-leading-4 footer-space-y-3",
};

const FooterComponent = () => {
  const { t, i18n } = useTranslation();
  const url = window.location.pathname;
  const geo = location.pathname.split("/")[1].substring(0, 2);

  const checkVisibility = () => {
    let app = window.location.pathname.split("/")[2];

    const excluded_apps = [
      "assist-pay",
      "productos",
      "products",
      "checkout",
      "checkout2",
      "payment_success",
      "payment_pending",
      "mi-voucher",
      "afiliados",
      "preguntas-frecuentes",
    ];
    return !excluded_apps.includes(app);
  };

  const visibility = checkVisibility();

  return (
    <>
      {visibility && (
        <footer className='footer-container footer-w-full footer-max-w-full footer-mx-auto footer-text-white footer-flex footer-items-center footer-justify-center'>
          <div
            className='
            flex
            flex-col
            footer-min-w-[358px]
            footer-max-w-[358px] 
            sm:footer-max-w-[633px] 
            xl:footer-max-w-[1210px] 
            footer-px-6 
            footer-py-16'
          >
            <div className='footer-flex footer-flex-col xl:footer-flex-row xl:footer-items-center xl:footer-justify-center'>
              {/* options */}
              <div className='footer-order-1 sm:footer-order-2'>
                <h4 className={styles.headings + " footer-mb-5"}>Assist 365</h4>

                <div className='footer-flex footer-flex-col sm:footer-flex-row'>
                  <div>
                    <h4 className={styles.headings + " footer-mb-6"}>
                      {t("headings.about")}
                    </h4>
                    <div>
                      <ul className={styles.options + " footer-mb-9"}>
                        <li>
                          <a
                            className='hover:footer-underline'
                            href={`/${geo}/preguntas-frecuentes`}
                          >
                            {t("options.about.faqs")}
                          </a>
                        </li>
                        <li>
                          <a
                            className='hover:footer-underline'
                            href={`/${geo}/mi-voucher`}
                          >
                            {t("options.about.myVoucher")}
                          </a>
                        </li>
                        <li>
                          <a
                            className='hover:footer-underline'
                            href={`/${geo}/reintegros-express`}
                          >
                            {t("options.about.refunds")}
                          </a>
                        </li>
                        {/* <li>
                          <a href="">{t("options.about.ccgg")}</a>
                        </li> */}
                        <li>
                          <a
                            className='hover:footer-underline'
                            href={`/${geo}/assist-365_en_viaje`}
                          >
                            {t("options.about.emergencies")}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className='sm:footer-ml-8'>
                    <h4
                      className={
                        styles.headings +
                        " footer-mb-6 + 'hover:footer-underline'"
                      }
                    >
                      {t("headings.workWithUs")}
                    </h4>
                    <ul className={styles.options + " footer-mb-6"}>
                      <li>
                        <a
                          className='hover:footer-underline'
                          href={`/${geo}/empresas`}
                        >
                          {t("options.workWithUs.enterprises")}
                        </a>
                      </li>
                      <li>
                        <a
                          className='hover:footer-underline'
                          href={`/${geo}/afiliados`}
                        >
                          {t("options.workWithUs.affiliates")}
                        </a>
                      </li>
                      <li>
                        <a
                          className='hover:footer-underline'
                          href={`/${geo}/ccgg`}
                        >
                          {t("options.about.ccgg")}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* social */}

              <div className='footer-order-2 sm:footer-order-1 xl:footer-mr-32'>
                <div className='footer-mx-auto footer-max-w-[315px] footer-flex footer-items-center footer-justify-center footer-flex-col'>
                  <h4 className={styles.headings + " footer-mb-4"}>
                    {t("headings.social")}
                  </h4>
                  <div className='footer-flex footer-justify-start footer-items-center footer-space-x-8'>
                    <a
                      target='_blank'
                      href={
                        geo === "br" || i18n.language === "pt-BR"
                          ? "https://www.youtube.com/@assist365br/featured"
                          : "https://www.youtube.com/c/Assist365_Seguro_de_viaje"
                      }
                    >
                      <img src={ico_social_5} alt='Youtube' />
                    </a>
                    {geo !== "br" && (
                      <a target='_blank' href='https://twitter.com/assist365ok'>
                        <img src={ico_social_1} alt='Twitter' />
                      </a>
                    )}
                    {geo !== "br" && (
                      <a
                        target='_blank'
                        href='https://ar.linkedin.com/company/assist-365'
                      >
                        <img src={ico_social_2} alt='Linkedin' />
                      </a>
                    )}
                    <a
                      target='_blank'
                      href={
                        geo === "br" || i18n.language === "pt-BR"
                          ? "https://www.instagram.com/assist365br/"
                          : "https://www.instagram.com/assist365ok/"
                      }
                    >
                      <img src={ico_social_3} alt='Instagram' />
                    </a>
                    {geo !== "br" && (
                      <a
                        target='_blank'
                        href='https://www.tiktok.com/@assist365ok'
                      >
                        <img src={ico_social_6} alt='TikTok' />
                      </a>
                    )}
                    <a
                      target='_blank'
                      href={
                        geo === "br" || i18n.language === "pt-BR"
                          ? "https://www.facebook.com/segurodeviagemassist365/"
                          : "https://www.facebook.com/assist.365.cover/"
                      }
                    >
                      <img src={ico_social_4} alt='Facebook' />
                    </a>
                  </div>
                </div>

                <div className='w-full mx-auto footer-py-6'>
                  <SwitchLanguage />
                </div>
              </div>
            </div>

            <div
              className='footer-flex footer-items-center footer-justify-between footer-text-sm footer-underline
                sm:footer-justify-center sm:footer-space-x-8 xl:footer-mt-4'
            >
              {geo !== "br" && i18n.language !== "pt-BR" && (
                <a
                  target='_blank'
                  href='https://app.assist-365.com/upload/politica_de_cookies.pdf'
                >
                  {t("cookies")}
                </a>
              )}
              {geo === "br" ||
                (i18n.language === "pt-BR" && (
                  <a
                    target='_blank'
                    href='https://assistcdn.s3.us-west-1.amazonaws.com/assets/site/br/files/Politica_de_Cookies.pdf'
                  >
                    {t("cookies")}
                  </a>
                ))}
              {geo !== "br" && i18n.language !== "pt-BR" && (
                <a
                  target='_blank'
                  href='https://app.assist-365.com/upload/politica_de_privacidad.pdf'
                >
                  {t("privacy")}
                </a>
              )}
              {geo === "br" ||
                (i18n.language === "pt-BR" && (
                  <a
                    target='_blank'
                    href='https://assistcdn.s3.us-west-1.amazonaws.com/assets/site/br/files/Politica_de_Privacidade.pdf'
                  >
                    {t("privacy")}
                  </a>
                ))}
            </div>
            <div className='footer-block footer-w-full mt-5 footer-text-sm footer-text-center footer-leading-4 footer-mt-6'>
              {t("copyright")}
            </div>
            <p className='footer-mt-20 sm:footer-mt-6 footer-text-xs footer-text-center'>
              {t("coverInfo")}
            </p>
            <p className='footer-mt-5 footer-text-xs footer-text-center'>
              {t("disclaimer")}
            </p>

            <div
              className='w-full footer-text-xs footer-font-semibold footer-mx-auto footer-text-center footer-mt-20
                sm:footer-mt-8 sm:footer-flex sm:footer-items-center sm:footer-justify-center xsm:footer-space-x-6'
            >
              <div className='w-full footer-flex footer-items-center footer-justify-around footer-flex-wrap'>
                <div className='footer-flex footer-items-center sm:footer-mr-6'>
                  <img className='footer-mr-2' src={ico_flag_3} />
                  Miami - USA
                </div>
                <div className='footer-flex footer-items-center'>
                  <img className='footer-mr-2' src={ico_flag_1} />
                  São Paulo - BRA
                </div>

                <div className='footer-flex footer-mt-3 sm:footer-mt-0 footer-items-center justify-center'>
                  <img className='footer-mr-2' src={ico_flag_2} />
                  Buenos Aires - ARG
                </div>
              </div>
            </div>
          </div>
        </footer>
      )}
    </>
  );
};

export default FooterComponent;
